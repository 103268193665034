import api from '../api/axios';

export interface ITestResponse {
	id_test: number;
	dt_vinculo: string;
	dt_conclusao: string;
	test: {
		id: number;
		txt_title: string;
		txt_description: string;
		fl_status: string;
		txt_url: string;
		dt_end: string;
		created_at: string;
		update_at: string;
	};
}

const getTests = async () => {
	const response = await api.get<ITestResponse[]>('/betatester/betatester-test');

	return response.data;
};

const updateTest = async ({ id_test }: { id_test: number }) => {
	const response = await api.put('/betatester/betatester-test', { id_test });

	return response.data;
};

const TestService = {
	getTests,
	updateTest
};

export default TestService;
