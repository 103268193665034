import useStyles from './styles';

interface IDividerTitleProps {
	color?: string;
	marginTop?: boolean;
	marginBottom?: boolean;
}

const DividerTitle = ({ color = '#F57B74', marginTop = true, marginBottom = true }: IDividerTitleProps) => {
	const styles = useStyles();

	return (
		<div
			className={styles.dividerTitle}
			style={{
				border: `5px solid ${color}`,
				marginTop: marginTop ? '24px' : '0',
				marginBottom: marginBottom ? '24px' : '0'
			}}
		></div>
	);
};

export default DividerTitle;
