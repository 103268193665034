import LogoBB from './svgs/bb-seguros.svg';
import IntroImage from '../../assets/images/imgs/intro.png';
import IntroMobileImage from '../images/imgs/intro-mobile.png';
import WhatIsIllustration from '../images/svgs/what-is-illustration.svg';
import VoiceIllustration from '../images/svgs/voice.svg';
import NewsIllustration from '../images/svgs/news.svg';
import AdvantageIllustration from '../images/svgs/advantage.svg';
import DogImage from '../images/imgs/dog.jpg';
import LogoBBBlue from '../images/svgs/bb-seguros-blue.svg';
import FacebookIcon from '../images/svgs/facebook.svg';
import InstagramIcon from '../images/svgs/instagram.svg';
import LinkedinIcon from '../images/svgs/linkedin.svg';
import YoutubeIcon from '../images/svgs/youtube.svg';
import LogoModalLogin from '../images/svgs/logo-modal-login.svg';
import MenuMobileIcon from '../images/svgs/menu.svg';
import WarningProfileIcon from '../images/svgs/warning-profile.svg';
import OkProfileIcon from '../images/svgs/ok.svg';
import ProfileOverlay from '../images/svgs/profile-overlay.svg';
import QuestionIcon from '../images/svgs/question.svg';
export {
	LogoBB,
	IntroImage,
	IntroMobileImage,
	WhatIsIllustration,
	VoiceIllustration,
	NewsIllustration,
	AdvantageIllustration,
	DogImage,
	LogoBBBlue,
	FacebookIcon,
	InstagramIcon,
	LinkedinIcon,
	YoutubeIcon,
	LogoModalLogin,
	MenuMobileIcon,
	WarningProfileIcon,
	OkProfileIcon,
	ProfileOverlay,
	QuestionIcon
};
