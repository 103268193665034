import { Button, Theme } from '@mui/material';
import { useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@mui/material';
import { LogoBB, MenuMobileIcon } from '../../assets/images/images';
import useStyles from './styles';
import { useEffect, useState } from 'react';
import MenuMobile from '../MenuMobilePublic';
import ModalLoginMobile from '../ModalLoginMobile';
import ModalSignUpMobile from '../ModalSignUpMobile';

interface INavBarProps {
	setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>;
	scrollToWhatIs: () => void;
	scrollToSignUp: () => void;
	scrollToFaq: () => void;
}

const NavBarPublic = ({ setOpenModal, scrollToWhatIs, scrollToSignUp, scrollToFaq }: INavBarProps) => {
	const [menuMobileOpen, setMenuMobileOpen] = useState<boolean>(false);
	const [modalLoginMobileOpen, setModalLoginMobileOpen] = useState<boolean>(false);
	const [modalSignUpMobileOpen, setModalSignUpMobileOpen] = useState<boolean>(false);
	const styles = useStyles();

	const theme = useTheme<Theme>();

	const isMobile = useMediaQuery('(max-width: 1076px)');

	const handleOpenModal = () => {
		if (setOpenModal) {
			setOpenModal(true);
		}
	};

	useEffect(() => {
		console.log(isMobile);
	});

	return (
		<>
			<MenuMobile
				menuMobileOpen={menuMobileOpen}
				setMenuMobileOpen={setMenuMobileOpen}
				setModalLoginMobileOpen={setModalLoginMobileOpen}
				setModalSignUpMobileOpen={setModalSignUpMobileOpen}
			/>
			<ModalLoginMobile
				modalLoginMobileOpen={modalLoginMobileOpen}
				setModalLoginMobileOpen={setModalLoginMobileOpen}
				setModalSignUpMobileOpen={setModalSignUpMobileOpen}
			/>
			<ModalSignUpMobile
				modalSignUpMobileOpen={modalSignUpMobileOpen}
				setModalSignUpMobileOpen={setModalSignUpMobileOpen}
				setModalLoginMobileOpen={setModalLoginMobileOpen}
			/>
			<div className={styles.containerHeader}>
				<header className={styles.header}>
					<nav className={styles.nav}>
						<div className={styles.logoWrapper}>
							<img src={LogoBB} className={styles.logoBB} alt="Logo BB Seguros" />
							<span className={styles.textBetaTesters}>SegTesters</span>
							{!isMobile && (
								<div className={styles.containerNav}>
									<div>
										<ul className={styles.linksWrapper}>
											<li className={styles.link} onClick={scrollToWhatIs}>
												O que é?
											</li>
											<li className={styles.link} onClick={scrollToFaq}>
												Perguntas frequentes
											</li>
										</ul>
									</div>
								</div>
							)}
						</div>
						{isMobile ? (
							<div style={{ cursor: 'pointer' }} onClick={() => setMenuMobileOpen(true)}>
								<img src={MenuMobileIcon} alt="Menu Mobile" />
							</div>
						) : (
							<>
								<div className={styles.containerButtons}>
									<Button
										sx={{
											bgcolor: '#E4ECFF',
											marginRight: '8px',
											fontFamily: 'bb-title-bold',
											'&:hover': { backgroundColor: 'white' }
										}}
										onClick={handleOpenModal}
									>
										LOGIN
									</Button>
									<Button
										sx={{
											bgcolor: theme.palette.secondary.main,
											fontFamily: 'bb-title-bold',
											'&:hover': { backgroundColor: '#f9f987' }
										}}
										onClick={scrollToSignUp}
									>
										CADASTRE-SE
									</Button>
								</div>
							</>
						)}
					</nav>
				</header>
			</div>
		</>
	);
};

export default NavBarPublic;
