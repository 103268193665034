import { Button, Dialog, IconButton, TextField } from '@mui/material';
import { useTheme } from '@material-ui/styles';
import { Theme } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import useStyles from './styles';
import Title from '../Title';
import { Close } from '@mui/icons-material';
import SignInService from '../../services/betatesterService';
import useBetaTesters from '../../contexts/useBetaTestersContext';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { maskCpf, unmaskCpf } from '../../utils/maskCpf';

interface IModalLoginMobile {
	modalLoginMobileOpen: boolean;
	setModalLoginMobileOpen: (value: boolean) => void;
	setModalSignUpMobileOpen: (value: boolean) => void;
}

interface IFormSignInInput {
	nr_cpf: string;
	dt_birth: string;
}

const ModalLoginMobile = ({
	modalLoginMobileOpen,
	setModalLoginMobileOpen,
	setModalSignUpMobileOpen
}: IModalLoginMobile) => {
	const styles = useStyles();

	const theme = useTheme<Theme>();

	const { control, handleSubmit } = useForm<IFormSignInInput>({
		defaultValues: {
			nr_cpf: '',
			dt_birth: ''
		}
	});

	const { onLoggedIn } = useBetaTesters();

	const mutation = useMutation({
		mutationFn: SignInService.signIn,
		onSuccess: onLoggedIn,
		onError: (error: AxiosError<{ message: string }>) => {
			if (error.response) {
				notify(error.response.data.message);
			}
		}
	});

	const onSubmit = (data: IFormSignInInput) => {
		console.log(data);
		const clearCpf = unmaskCpf(data.nr_cpf);

		mutation.mutate({ ...data, nr_cpf: clearCpf });
	};

	const notify = (message: string) => {
		toast.error(message);
	};

	return (
		<Dialog open={modalLoginMobileOpen} fullScreen>
			<div className={styles.loginWrapper}>
				<div className={styles.titleWrapper}>
					<Title fontSize="22px" color="#4E5155">
						Login
					</Title>
					<IconButton onClick={() => setModalLoginMobileOpen(false)}>
						<Close sx={{ color: '#313338' }} />
					</IconButton>
				</div>
				<form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '24px' }}>
					<Controller
						name="nr_cpf"
						control={control}
						rules={{ required: true, pattern: /[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}/ }}
						render={({ field: { name, onChange, value }, fieldState: { error } }) => {
							return (
								<TextField
									name={name}
									value={value}
									onChange={event => onChange(maskCpf(event.target.value))}
									type="text"
									label="CPF"
									error={!!error}
									helperText={error && 'CPF inválido.'}
									placeholder="000.000.000-00"
									variant="filled"
									sx={{ color: '#F0F2F4' }}
									fullWidth
								/>
							);
						}}
					/>
					<Controller
						name="dt_birth"
						control={control}
						rules={{ required: true }}
						render={({ field: { name, onChange, value }, fieldState: { error } }) => {
							return (
								<TextField
									InputLabelProps={{ shrink: true }}
									type="date"
									label="Data de nascimento"
									placeholder="dd/mm/aaaa"
									name={name}
									error={!!error}
									value={value}
									onChange={onChange}
									helperText={error && 'Preecha o campo corretamente.'}
									variant="filled"
									sx={{ color: '#F0F2F4', marginTop: '20px' }}
									fullWidth
								/>
							);
						}}
					/>

					<Button
						sx={{
							padding: '12px 0',
							marginTop: '25px',
							bgcolor: theme.palette.secondary.main,
							fontFamily: 'bb-title-bold',
							'&:hover': {
								backgroundColor: theme.palette.secondary.main
							}
						}}
						type="submit"
						fullWidth
					>
						Entrar
					</Button>
				</form>
				<div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
					<Button
						onClick={() => {
							setModalSignUpMobileOpen(true);
							setModalLoginMobileOpen(false);
						}}
						variant="text"
						sx={{ color: '#465EFF', fontFamily: 'bb-text-medium', fontSize: '16px' }}
					>
						Ainda não sou cadastrado
					</Button>
				</div>
			</div>
		</Dialog>
	);
};

export default ModalLoginMobile;
