import { Button, Dialog, DialogContent, IconButton } from '@mui/material';
import Title from '../Title';
import { Cancel } from '@mui/icons-material';
import Paragraph from '../Paragraph';
import useStyles from './styles';

interface IQuitQuestionModal {
	modalOpen: boolean;
	setModalOpen(value: boolean): void;
	quitButtonFn: (target: string) => void;
	target: string;
}

const QuitQuestionsModal = ({ modalOpen, setModalOpen, quitButtonFn, target }: IQuitQuestionModal) => {
	const handleCloseModal = () => {
		setModalOpen(false);
	};

	const styles = useStyles();

	return (
		<>
			<Dialog open={modalOpen}>
				<DialogContent>
					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<Title fontSize="22px" color="#001527">
							Sair do questionário
						</Title>
						<IconButton onClick={handleCloseModal}>
							<Cancel sx={{ color: '#888D95' }} />
						</IconButton>
					</div>
					<Paragraph fontSize="14px" style={{ color: '#66707D', maxWidth: '320px', marginTop: '10px' }}>
						Tem certeza que quer sair do questionário? Sua informações não ficarão salvas. Você pode voltar e começar de
						novo a qualquer momento.
					</Paragraph>
					<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '24px' }}>
						<Button
							sx={{ backgroundColor: '#E4ECFF', color: '#3354FD', fontFamily: 'bb-title-bold', fontSize: '12px' }}
							className={styles.exitButton}
							variant="contained"
							onClick={() => quitButtonFn(target)}
						>
							SAIR
						</Button>
						<Button
							variant="contained"
							onClick={handleCloseModal}
							className={styles.keepButton}
							sx={{ backgroundColor: '#FDF429', color: '#3354FD', fontFamily: 'bb-title-bold', fontSize: '12px' }}
						>
							CONTINUAR PREENCHENDO
						</Button>
					</div>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default QuitQuestionsModal;
