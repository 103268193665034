import { Button, Dialog, IconButton } from '@mui/material';
import { Close, ArrowForwardIos } from '@mui/icons-material';
import Title from '../Title';
import useStyles from './styles';

interface IMenuMobile {
	menuMobileOpen: boolean;
	setMenuMobileOpen: (value: boolean) => void;
	setModalLoginMobileOpen: (value: boolean) => void;
	setModalSignUpMobileOpen: (value: boolean) => void;
}

const MenuMobile = ({
	menuMobileOpen,
	setMenuMobileOpen,
	setModalLoginMobileOpen,
	setModalSignUpMobileOpen
}: IMenuMobile) => {
	const styles = useStyles();

	return (
		<Dialog open={menuMobileOpen} fullScreen>
			<div className={styles.menuWrapper}>
				<div className={styles.titleWrapper}>
					<Title fontSize="22px" color="#4E5155">
						Menu
					</Title>
					<IconButton onClick={() => setMenuMobileOpen(false)}>
						<Close sx={{ color: '#313338' }} />
					</IconButton>
				</div>
				<div>
					<Button
						sx={{ justifyContent: 'space-between', fontFamily: 'bb-text-medium', fontSize: '18px', color: '#465EFF' }}
						variant="text"
						fullWidth
						endIcon={<ArrowForwardIos />}
						onClick={() => setModalLoginMobileOpen(true)}
					>
						Login
					</Button>
					<Button
						sx={{ justifyContent: 'space-between', fontFamily: 'bb-text-medium', fontSize: '18px', color: '#465EFF' }}
						variant="text"
						fullWidth
						endIcon={<ArrowForwardIos />}
						onClick={() => setModalSignUpMobileOpen(true)}
					>
						Cadastrar-se
					</Button>
				</div>
			</div>
		</Dialog>
	);
};

export default MenuMobile;
