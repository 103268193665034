import { Button, Dialog, DialogContent, IconButton, Slide, useMediaQuery } from '@mui/material';
import { Close } from '@mui/icons-material';
import { TransitionProps } from '@mui/material/transitions';
import { ProfileOverlay } from '../../assets/images/images';
import Title from '../Title';
import DividerTitle from '../DiviverTitle';
import Paragraph from '../Paragraph';
import { ReactElement, forwardRef, useEffect } from 'react';
import { PayloadProfile } from '../../utils/decodeToken';
import secureStorage from 'react-secure-storage';

interface IProfileQuestionsOverlay {
	profileQuestionsOverlayOpen: boolean;
	setProfileQuestionsOverlayOpen: (value: boolean) => void;
	setQuestionMobileModalOpen: (value: boolean) => void;
}

const Transition = forwardRef(function Transition(props: TransitionProps & { children: ReactElement }, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const ProfileQuestionsOverlay = ({
	profileQuestionsOverlayOpen,
	setProfileQuestionsOverlayOpen,
	setQuestionMobileModalOpen
}: IProfileQuestionsOverlay) => {
	const { txt_question_status } = secureStorage.getItem('userProfile') as PayloadProfile;

	const mobile = useMediaQuery('(max-width: 600px)');

	useEffect(() => {
		if (txt_question_status !== 'CP') {
			if (mobile) {
				setProfileQuestionsOverlayOpen(true);
			}
		}
	}, []);

	return (
		<Dialog open={profileQuestionsOverlayOpen} fullScreen TransitionComponent={Transition}>
			<DialogContent>
				<div>
					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<div>
							<div>
								<Title fontSize="22px" color="#465EFF">
									Questionário de perfil
								</Title>
							</div>
						</div>
						<IconButton onClick={() => setProfileQuestionsOverlayOpen(false)} sx={{ marginLeft: '20px' }}>
							<Close sx={{ color: '#313338' }} />
						</IconButton>
					</div>
				</div>
				<DividerTitle marginBottom={false} />
				<div style={{ marginTop: '32px', display: 'flex', justifyContent: 'center' }}>
					<img src={ProfileOverlay} alt="Imagem Overlay" />
				</div>
				<div style={{ marginTop: '32px' }}>
					<Paragraph>
						Você já faz parte da comunidade SegTesters da BB Seguros! Agora compartilhe com a gente mais informações
						sobre você e seu perfil. Elas são muito importantes para construirmos produtos cada vez melhores, que cuidam
						e protegem as conquistas de mais de 1 milhão de segurados.
					</Paragraph>
				</div>
				<div>
					<Button
						fullWidth
						sx={{
							fontFamily: 'bb-title-bold',
							fontSize: '16px',
							borderRadius: '4px',
							padding: '10px 32px',
							backgroundColor: '#FDF429',
							marginTop: '32px',
							color: '#465EFF',
							'&:hover': {
								backgroundColor: '#FDF429'
							}
						}}
						onClick={() => setQuestionMobileModalOpen(true)}
					>
						INICIAR QUESTIONÁRIO
					</Button>
					<Button
						fullWidth
						sx={{
							fontFamily: 'bb-title-bold',
							fontSize: '16px',
							borderRadius: '4px',
							padding: '10px 32px',
							backgroundColor: '#E4ECFF',
							marginTop: '16px',
							color: '#465EFF',
							'&:hover': {
								backgroundColor: '#E4ECFF'
							}
						}}
						onClick={() => setProfileQuestionsOverlayOpen(false)}
					>
						PREENCHER DEPOIS
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default ProfileQuestionsOverlay;
