import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
	progressMainWrapper: {
		marginBottom: '40px'
	},
	progressWrapper: {
		width: '100%',
		height: '4px',
		borderRadius: '4px',
		backgroundColor: '#E4ECFF'
	},
	progressContent: {
		height: '4px',
		borderRadius: '4px',
		backgroundColor: '#3354FD',
		transition: '.5s'
	},
	progressInfoWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: '10px'
	},
	progressInfoTitle: {
		fontFamily: 'bb-text-medium',
		fontSize: '14px',
		color: '#3354FD'
	},
	progressInfo: {
		fontFamily: 'bb-text-medium',
		color: '#111214'
	}
});

export default useStyles;
