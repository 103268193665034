import Paragraph from '../Paragraph';
import Title from '../Title';
import useStyles from './styles';

interface IBenefitCardProps {
	imageSrc: string;
	title: string;
	text: string;
	textColor?: string;
}

const BenefitCard = ({ imageSrc, title, text, textColor }: IBenefitCardProps) => {
	const styles = useStyles();

	return (
		<div className={styles.benefitWrapper}>
			<div>
				<img src={imageSrc} alt="Benefícios Ilustração" className={styles.illustration} />
				<Title fontSize="26px" color={textColor}>
					{title}
				</Title>
				<Paragraph fontSize="16px" style={{ marginTop: '16px', lineHeight: '25.6px' }}>
					{text}
				</Paragraph>
			</div>
		</div>
	);
};

export default BenefitCard;
