import { Button, Dialog, IconButton } from '@mui/material';
import { Close, ArrowForwardIos } from '@mui/icons-material';
import Title from '../Title';
import useStyles from './styles';
import { WarningProfileIcon, OkProfileIcon } from '../../assets/images/images';
import secureStorage from 'react-secure-storage';
import { useNavigate } from 'react-router-dom';
import { PayloadProfile } from '../../utils/decodeToken';
import useBetaTesters from '../../contexts/useBetaTestersContext';

interface IMenuMobile {
	menuMobileOpen: boolean;
	setMenuMobileOpen: (value: boolean) => void;
	setMyAccountMobileModalOpen: (value: boolean) => void;
}

const MenuMobile = ({ menuMobileOpen, setMenuMobileOpen, setMyAccountMobileModalOpen }: IMenuMobile) => {
	const styles = useStyles();

	const { txt_name, txt_email, txt_question_status } = secureStorage.getItem('userProfile') as PayloadProfile;

	const { onLogout } = useBetaTesters();

	const navigate = useNavigate();

	return (
		<Dialog open={menuMobileOpen} fullScreen>
			<div className={styles.menuWrapper}>
				<div className={styles.titleWrapper}>
					<Title fontSize="22px" color="#4E5155">
						Menu
					</Title>
					<IconButton onClick={() => setMenuMobileOpen(false)}>
						<Close sx={{ color: '#313338' }} />
					</IconButton>
				</div>
				<div>
					<Button
						sx={{ justifyContent: 'space-between', fontFamily: 'bb-text-medium', fontSize: '18px', color: '#465EFF' }}
						variant="text"
						fullWidth
						endIcon={<ArrowForwardIos />}
						onClick={() => {
							navigate('/');
							setMenuMobileOpen(false);
						}}
					>
						Meus testes
					</Button>
					<Button
						sx={{ justifyContent: 'space-between', fontFamily: 'bb-text-medium', fontSize: '18px', color: '#465EFF' }}
						variant="text"
						fullWidth
						endIcon={<ArrowForwardIos />}
						onClick={() => {
							navigate('/sobre');
							setMenuMobileOpen(false);
						}}
					>
						Sobre os SegTesters
					</Button>
					<Button
						sx={{ justifyContent: 'space-between', fontFamily: 'bb-text-medium', fontSize: '18px', color: '#465EFF' }}
						variant="text"
						fullWidth
						endIcon={<ArrowForwardIos />}
						onClick={() => {
							navigate('/faq');
							setMenuMobileOpen(false);
						}}
					>
						Perguntas frequentes
					</Button>
				</div>
				<div className={styles.titleWrapper}>
					<Title fontSize="22px" color="#4E5155">
						Conta
					</Title>
				</div>
				<div className={styles.userInfoWrapper}>
					<span className={styles.userInitials}>{txt_name.substring(0, 2)}</span>
					<div>
						<span className={styles.userName}>{txt_name}</span>
						<span className={styles.userEmail}>{txt_email}</span>
					</div>
				</div>
				<div style={{ marginTop: '12px' }}>
					<Button
						onClick={() => setMyAccountMobileModalOpen(true)}
						sx={{ justifyContent: 'space-between', fontFamily: 'bb-text-medium', fontSize: '18px', color: '#465EFF' }}
						variant="text"
						fullWidth
						endIcon={
							<div>
								<img
									style={{ marginRight: '8px' }}
									src={txt_question_status !== 'CP' ? WarningProfileIcon : OkProfileIcon}
									alt="Warning Profile"
								/>
								<ArrowForwardIos />
							</div>
						}
					>
						Sua conta
					</Button>
					<Button
						sx={{ justifyContent: 'space-between', fontFamily: 'bb-text-medium', fontSize: '18px', color: '#465EFF' }}
						variant="text"
						fullWidth
						endIcon={<ArrowForwardIos />}
						onClick={onLogout}
					>
						Sair
					</Button>
				</div>
			</div>
		</Dialog>
	);
};

export default MenuMobile;
