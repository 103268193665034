import { ClickAwayListener, IconButton, useMediaQuery } from '@mui/material';
import { ArrowForwardIos, CheckCircle, Warning } from '@mui/icons-material';
import { LogoBB, MenuMobileIcon } from '../../assets/images/images';
import { useTheme } from '@material-ui/styles';
import { Theme } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import secureStorage from 'react-secure-storage';
import useStyles from './styles';
import useBetaTesters from '../../contexts/useBetaTestersContext';
import { useRef, useState } from 'react';
import { PayloadProfile } from '../../utils/decodeToken';
import QuitQuestionsModal from '../QuitQuestionsModal';
import MyAccountModal from '../MyAccountModal';
import DeleteAccountModal from '../DeleteAccountModal';
import MenuMobile from '../MenuMobile';
import MyAccountMobileModal from '../MyAccountMobileModal';
import DeleteAccountMobileModal from '../DeleteAccountMobileModal';
import TermModal from '../TermModal';

const NavBarBetaTester = () => {
	const [modalOpen, setModalOpen] = useState(false);
	const [myAccountModalOpen, setMyAccountModalOpen] = useState<boolean>(true);
	const [myAccountMobileModalOpen, setMyAccountMobileModalOpen] = useState<boolean>(true);
	const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState<boolean>(true);
	const [deleteAccountMobileModalOpen, setDeleteAccountMobileModalOpen] = useState<boolean>(true);
	const [menuMobileOpen, setMenuMobileOpen] = useState(false);
	const [dropdownMenuOpen, setDropdownMenuOpen] = useState<boolean>(false);
	const [termModal, setTermModal] = useState<boolean>(false);
	const [target, setTarget] = useState<string>('');
	const [profile] = useState(() => {
		const token = secureStorage.getItem('token') as string;

		if (!token) {
			return null;
		}

		const payload = secureStorage.getItem('userProfile') as PayloadProfile;

		return payload;
	});

	const dropdownElement = useRef<HTMLDivElement>(null);

	const location = useLocation();

	const styles = useStyles();

	const theme = useTheme<Theme>();

	const isMobile = useMediaQuery('(max-width: 1000px)');

	const navigate = useNavigate();

	const { onLogout, questionStatus } = useBetaTesters();

	const onClickLink = (target: string) => {
		if (location.pathname === '/perguntas') {
			setTarget(target);
			setModalOpen(true);
		} else {
			navigate(target);
		}
	};

	const onLinkTo = (target: string) => {
		setModalOpen(false);
		navigate(target);
	};

	return (
		<>
			<MenuMobile
				menuMobileOpen={menuMobileOpen}
				setMenuMobileOpen={setMenuMobileOpen}
				setMyAccountMobileModalOpen={setMyAccountMobileModalOpen}
			/>
			<DeleteAccountModal
				deleteAccountModalOpen={deleteAccountModalOpen}
				setDeleteAccountModalOpen={setDeleteAccountModalOpen}
			/>
			<DeleteAccountMobileModal
				deleteAccountMobileModalOpen={deleteAccountMobileModalOpen}
				setDeleteAccountMobileModalOpen={setDeleteAccountMobileModalOpen}
			/>
			<MyAccountMobileModal
				myAccountMobileModalOpen={myAccountMobileModalOpen}
				setMyAccountMobileModalOpen={setMyAccountMobileModalOpen}
				setDeleteAccountMobileModalOpen={setDeleteAccountMobileModalOpen}
			/>
			<MyAccountModal
				myAccountModalOpen={myAccountModalOpen}
				setAccountModalOpen={setMyAccountModalOpen}
				setDeleteAccountModalOpen={setDeleteAccountModalOpen}
				setTermModal={setTermModal}
			/>
			<TermModal termModalOpen={termModal} setTermModalOpen={setTermModal} />
			<QuitQuestionsModal modalOpen={modalOpen} setModalOpen={setModalOpen} quitButtonFn={onLinkTo} target={target} />
			<div className={styles.containerHeader}>
				<header className={styles.header}>
					<nav className={styles.nav}>
						<div className={styles.logoWrapper}>
							<img src={LogoBB} className={styles.logoBB} alt="Logo BB Seguros" />
							<span className={styles.textBetaTesters}>SegTesters</span>
							{!isMobile && (
								<div className={styles.containerNav}>
									<div>
										<ul className={styles.linksWrapper}>
											<>
												<li>
													<a
														onClick={() => onClickLink('/')}
														className={styles.link}
														style={{
															margin: '0 32px 0 0',
															padding: 0,
															color: location.pathname === '/' ? '#FCFC30' : '#ffffff'
														}}
													>
														Meus testes
													</a>
												</li>
											</>
											<li>
												<a
													onClick={() => onClickLink('/sobre')}
													className={styles.link}
													style={{ color: location.pathname === '/sobre' ? '#FCFC30' : '#ffffff' }}
												>
													O que é?
												</a>
											</li>
											<li>
												<a
													onClick={() => onClickLink('/faq')}
													className={styles.link}
													style={{ color: location.pathname === '/faq' ? '#FCFC30' : '#ffffff' }}
												>
													Perguntas frequentes
												</a>
											</li>
										</ul>
									</div>
								</div>
							)}
						</div>
						{isMobile ? (
							<div style={{ cursor: 'pointer' }} onClick={() => setMenuMobileOpen(true)}>
								<img src={MenuMobileIcon} alt="Menu Mobile" />
							</div>
						) : (
							<>
								<div className={styles.containerUserInfo}>
									<div className={styles.userInfoWrapper}>
										<div className={styles.avatar}>{profile?.txt_name.substring(0, 2)}</div>
										<div>
											<span className={styles.userName}>{profile?.txt_name.split(' ')[0]}</span>
											<span className={styles.userEmail}>{profile?.txt_email}</span>
										</div>
									</div>
									<IconButton
										className={styles.logoutButton}
										sx={{
											padding: 0,
											marginLeft: '38px',
											transform: 'rotate(90deg)'
										}}
										aria-label="Logout"
										onClick={() => {
											setDropdownMenuOpen(true);
										}}
									>
										<ArrowForwardIos
											fontSize="small"
											sx={{ color: '#FDFEFF', '&:hover': { color: theme.palette.secondary.main } }}
										/>
									</IconButton>
									{dropdownMenuOpen && (
										<ClickAwayListener onClickAway={() => setDropdownMenuOpen(false)}>
											<div ref={dropdownElement} className={styles.dropdownWrapper}>
												<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
													<span className={styles.dropdownMenu} onClick={() => setMyAccountModalOpen(true)}>
														SUA CONTA
														{questionStatus === 'CP' ? (
															<div>
																<CheckCircle sx={{ color: '#00907F' }} />
															</div>
														) : (
															<div>
																<Warning sx={{ color: '#E3A702' }} />
															</div>
														)}
													</span>
												</div>
												<span onClick={onLogout} className={styles.dropdownMenu}>
													SAIR
												</span>
											</div>
										</ClickAwayListener>
									)}
								</div>
							</>
						)}
					</nav>
				</header>
			</div>
		</>
	);
};

export default NavBarBetaTester;
