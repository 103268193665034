import { ArrowForwardIos } from '@mui/icons-material';

const ArrowButton = ({
	side = 'left',
	onClick
}: {
	side?: 'left' | 'right';
	onClick?: React.MouseEventHandler<SVGSVGElement>;
}) => {
	return (
		<>
			{side === 'left' ? (
				<ArrowForwardIos
					onClick={onClick}
					sx={{ color: '#FDFEFF', transform: 'rotate(-180deg)' }}
					style={{
						alignItems: 'center',
						padding: '0 12px',
						height: '100%',
						backgroundColor: '#0000004D',
						cursor: 'pointer',
						position: 'absolute',
						left: 0
					}}
				/>
			) : (
				<ArrowForwardIos
					onClick={onClick}
					sx={{ color: '#FDFEFF' }}
					style={{
						alignItems: 'center',
						padding: '0 12px',
						height: '100%',
						backgroundColor: '#0000004D',
						cursor: 'pointer',
						position: 'absolute',
						right: 0
					}}
				/>
			)}
		</>
	);
};

export default ArrowButton;
