import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
	customDotClass: {
		position: 'relative',
		marginTop: '10px'
	}
});

export default useStyles;
