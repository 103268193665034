import { makeStyles } from '@material-ui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
	containerHeader: {
		width: '100%',
		position: 'fixed',
		top: 0,
		zIndex: 2
	},
	header: {
		backgroundColor: theme.palette.primary.main,
		padding: '28px 36px',
		margin: '10px 45px 0 45px',
		borderRadius: 16,
		'@media (max-width: 1076px)': {
			margin: '0',
			borderRadius: 0,
			padding: '22px 16px'
		}
	},
	nav: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	containerNav: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: '24px'
	},
	logoWrapper: {
		display: 'flex',
		alignItems: 'center'
	},
	logoBB: {
		width: 200,
		'@media (max-width: 1076px)': {
			width: 120
		}
	},
	textBetaTesters: {
		fontFamily: 'JetBrainsMono-Medium',
		marginLeft: 16,
		fontSize: '24px',
		color: '#FFFFFF',
		'@media (max-width: 1076px)': {
			fontSize: '18px'
		}
	},
	linksWrapper: {
		display: 'flex',
		alignItems: 'center',
		padding: 0
	},
	userLink: {
		listStyle: 'none',
		fontFamily: 'bb-title-bold',
		color: '#ffffff'
	},
	link: {
		listStyle: 'none',
		fontFamily: 'bb-title-bold',
		color: '#ffffff',
		marginRight: 32,
		cursor: 'pointer',
		transition: '1s',
		'&:hover': {
			color: theme.palette.secondary.main
		}
	},
	containerUserInfo: {
		display: 'flex',
		alignItems: 'center'
	},
	userInfoWrapper: {
		display: 'flex'
	},
	avatar: {
		padding: 8,
		backgroundColor: '#EDF2FF',
		fontFamily: 'bb-text-medium',
		fontSize: 16,
		borderRadius: 4,
		color: '#265EFD',
		marginRight: 10
	},
	userName: {
		fontFamily: 'bb-text-medium',
		color: '#FDFEFF'
	},
	userEmail: {
		fontFamily: 'bb-text-regular',
		fontSize: 14,
		marginTop: 5,
		color: '#FDFEFF'
	},
	containerButtons: {
		display: 'flex'
	}
}));
export default useStyles;
