import { CSSProperties, ReactNode } from 'react';
import useStyles from './styles';

interface IContainerProps {
	children: ReactNode;
	width?: number | string;
	style?: CSSProperties;
}

const Container = ({ children, width = '1240px', style }: IContainerProps) => {
	const styles = useStyles();

	return (
		<div className={styles.container} style={{ ...style, maxWidth: width }}>
			{children}
		</div>
	);
};

export default Container;
