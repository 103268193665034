import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
	card: {
		height: '190px',
		padding: '20px 24px',
		backgroundColor: '#F9F7FF',
		border: '1px solid #B3B3B3',
		borderRadius: '10px',
		flexShrink: 0,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		marginRight: '15px'
	}
});

export default useStyles;
