import { CSSProperties, ReactNode } from 'react';
import useStyles from './styles';

interface ITitleProps {
	children: string | ReactNode;
	fontSize?: string | number;
	style?: CSSProperties;
	color?: string;
}

const Title = ({ children, fontSize = '32px', style, color = '#465EFF' }: ITitleProps) => {
	const styles = useStyles();

	return (
		<h2 className={styles.title} style={{ ...style, fontSize, color }}>
			{children}
		</h2>
	);
};

export default Title;
