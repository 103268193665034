import secureStorage from 'react-secure-storage';
import api from '../api/axios';

const verifyToken = async () => {
	const token = secureStorage.getItem('token');

	if (token) {
		const response = await api.post<{ tokenStatus: boolean }>('/betatester/session/validate');

		return response.data;
	}
};

const TokenService = {
	verifyToken
};

export default TokenService;
