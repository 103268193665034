import api from '../api/axios';

type Answer = {
	id: number;
	id_question: number;
	txt_answer: string;
};

interface IQuestionResponse {
	id: number;
	tp_input: 'CH' | 'SE' | 'RA';
	nr_order: number;
	txt_question: string;
	txt_aux: string;
	txt_answers: Answer[];
}

const getQuestions = async () => {
	const response = await api.get<IQuestionResponse[]>('/betatester/question');

	return response.data;
};

const QuestionService = {
	getQuestions
};

export default QuestionService;
