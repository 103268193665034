import api from '../api/axios';

interface ISignIn {
	nr_cpf: string;
	dt_birth: string;
}

interface ISignUp {
	nr_cpf: string;
	txt_name: string;
	txt_email: string;
	dt_birth: string;
}

interface IUpdateAccount {
	txt_email: string;
}

const signIn = async ({ nr_cpf, dt_birth }: ISignIn) => {
	const response = await api.post('/betatester/session', { nr_cpf, dt_birth });

	return response.data;
};

const signUp = async ({ nr_cpf, txt_name, txt_email, dt_birth }: ISignUp) => {
	const response = await api.post('/betatester', { nr_cpf, txt_name, txt_email, dt_birth });

	return response.data;
};

const deleteAccount = async () => {
	const response = await api.delete('/betatester');

	return response.data;
};

const updateAccount = async ({ txt_email }: IUpdateAccount) => {
	const response = await api.put('/betatester', { txt_email });

	return response.data;
};

const getQuestionStatus = async () => {
	const response = await api.get<{ txt_question_status: 'IN' | 'CP' | 'IN_RE' | null }>('/betatester/question-status');

	return response.data;
};

const BetatesterService = {
	signIn,
	signUp,
	deleteAccount,
	updateAccount,
	getQuestionStatus
};

export default BetatesterService;
