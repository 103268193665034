import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
	menuWrapper: {
		padding: '18px 16px'
	},
	titleWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '10px',
		borderBottom: '1px solid #B4B9C1'
	}
});

export default useStyles;
