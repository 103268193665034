const maskCpf = (cpf: string) => {
	return cpf
		.replace(/\D/g, '')
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d{1,2})/, '$1-$2')
		.replace(/(-\d{2})\d+?$/, '$1');
};

const unmaskCpf = (cpf: string) => cpf.replace(/(\.|\/|-)/g, '');

export { maskCpf, unmaskCpf };
