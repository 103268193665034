import { jwtDecode } from 'jwt-decode';
import secureStorage from 'react-secure-storage';

export interface PayloadProfile {
	id: number;
	txt_name: string;
	txt_email: string;
	dt_birth: string;
	nr_cpf: number;
	txt_question_status: 'CP' | 'IN' | 'IN_RE';
}

const decodeToken = () => {
	const token = secureStorage.getItem('token') as string;

	const profilePayload = jwtDecode<PayloadProfile>(token);

	return profilePayload;
};

export { decodeToken };
