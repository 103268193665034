import { Dialog, DialogContent, Stack, TextField, Button, IconButton, Slide } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import { useTheme } from '@material-ui/styles';
import { Theme } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import useStyles from './styles';
import { LogoModalLogin } from '../../assets/images/images';
import Title from '../Title';
import { ReactElement, forwardRef } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { maskCpf, unmaskCpf } from '../../utils/maskCpf';
import SignInService from '../../services/betatesterService';
import { useMutation } from 'react-query';
import Loading from '../Loading';
import useBetaTesters from '../../contexts/useBetaTestersContext';

interface IModalLoginProps {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IFormSignInInput {
	nr_cpf: string;
	dt_birth: string;
}

const Transition = forwardRef(function Transition(props: TransitionProps & { children: ReactElement }, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const ModalLogin = ({ open, setOpen }: IModalLoginProps) => {
	const styles = useStyles();

	const theme = useTheme<Theme>();

	const { control, handleSubmit } = useForm<IFormSignInInput>({
		defaultValues: {
			nr_cpf: '',
			dt_birth: ''
		}
	});

	const { onLoggedIn } = useBetaTesters();

	const mutation = useMutation({
		mutationFn: SignInService.signIn,
		onSuccess: onLoggedIn,
		onError: (error: AxiosError<{ message: string }>) => {
			if (error.response) {
				notify(error.response.data.message);
			}
		}
	});

	const onSubmit = (data: IFormSignInInput) => {
		console.log(data);
		const clearCpf = unmaskCpf(data.nr_cpf);

		mutation.mutate({ ...data, nr_cpf: clearCpf });
	};

	const notify = (message: string) => {
		toast.error(message);
	};

	const handleCloseModal = () => {
		setOpen(false);
	};

	return (
		<>
			{mutation.isLoading && <Loading />}
			<Dialog open={open} onClose={handleCloseModal} TransitionComponent={Transition}>
				<DialogContent>
					<div className={styles.modalWrapper}>
						<div className={styles.closeButtonWrapper}>
							<IconButton onClick={handleCloseModal}>
								<Cancel sx={{ color: '#888D95' }} />
							</IconButton>
						</div>
						<Stack alignItems="center">
							<Stack alignItems="center">
								<img src={LogoModalLogin} alt="Logo BB Seguros" width="105px" />
								<span className={styles.logoSpan}>segTesters</span>
							</Stack>
							<Title color="#001527" fontSize="26px" style={{ marginTop: '16px', marginBottom: '32px' }}>
								Área do SegTester
							</Title>
							<form onSubmit={handleSubmit(onSubmit)}>
								<Controller
									name="nr_cpf"
									control={control}
									rules={{ required: true, pattern: /[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}/ }}
									render={({ field: { name, onChange, value }, fieldState: { error } }) => {
										return (
											<TextField
												name={name}
												value={value}
												onChange={event => onChange(maskCpf(event.target.value))}
												type="text"
												label="CPF"
												error={!!error}
												helperText={error && 'CPF inválido.'}
												placeholder="000.000.000-00"
												variant="filled"
												sx={{ color: '#F0F2F4' }}
												fullWidth
											/>
										);
									}}
								/>
								<Controller
									name="dt_birth"
									control={control}
									rules={{ required: true }}
									render={({ field: { name, onChange, value }, fieldState: { error } }) => {
										return (
											<TextField
												InputLabelProps={{ shrink: true }}
												type="date"
												label="Data de nascimento"
												placeholder="dd/mm/aaaa"
												name={name}
												error={!!error}
												value={value}
												onChange={onChange}
												helperText={error && 'Preecha o campo corretamente.'}
												variant="filled"
												sx={{ color: '#F0F2F4', marginTop: '20px' }}
												fullWidth
											/>
										);
									}}
								/>

								<Button
									sx={{
										padding: '12px 0',
										marginTop: '25px',
										bgcolor: theme.palette.secondary.main,
										fontFamily: 'bb-title-bold',
										'&:hover': {
											backgroundColor: theme.palette.secondary.main
										}
									}}
									type="submit"
									fullWidth
								>
									Entrar
								</Button>
							</form>
						</Stack>
					</div>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default ModalLogin;
