import { createContext, useMemo, useState, ReactNode, useEffect } from 'react';
import secureStorage from 'react-secure-storage';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import ContextTypes from './contextTypes';
import api from '../api/axios';
import TokenService from '../services/tokenService';
import { decodeToken } from '../utils/decodeToken';
import BetatesterService from '../services/betatesterService';

export const BetaTestersContext = createContext({} as ContextTypes);

interface BetaTestersContextProps {
	children: ReactNode;
}

const BetaTestersProvider = ({ children }: BetaTestersContextProps) => {
	const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [questionMobileModalOpen, setQuestionMobileModalOpen] = useState<boolean>(false);
	const [questionStatus, setQuestionStatus] = useState<'IN' | 'CP' | 'IN_RE' | null>(null);

	const navigate = useNavigate();

	const validateMutation = useMutation({
		mutationFn: TokenService.verifyToken,
		onSuccess: data => {
			if (data?.tokenStatus) {
				setIsLoggedIn(true);
			} else {
				setIsLoggedIn(false);
				secureStorage.removeItem('token');
			}
		},
		onError: () => {
			setIsLoggedIn(false);
			secureStorage.removeItem('token');
		}
	});

	const statusQuery = useQuery({
		queryFn: BetatesterService.getQuestionStatus,
		onSuccess: data => {
			setQuestionStatus(data.txt_question_status);
		},
		enabled: false
	});

	const onLoggedIn = (token: string) => {
		api.defaults.headers.authorization = token;
		secureStorage.setItem('token', token);
		const userProfile = decodeToken();

		secureStorage.setItem('userProfile', userProfile);

		setIsLoggedIn(true);
	};

	const onLogout = () => {
		api.defaults.headers.authorization = '';
		secureStorage.removeItem('token');
		secureStorage.removeItem('userProfile');
		secureStorage.removeItem('logged');
		setIsLoggedIn(false);
		navigate('/');
	};

	useEffect(() => {
		const token = secureStorage.getItem('token');
		api.defaults.headers.authorization = token as string;
		validateMutation.mutate();
	}, []);

	const value = useMemo(() => {
		return {
			isLoggedIn,
			setIsLoggedIn,
			onLoggedIn,
			onLogout,
			openModal,
			setOpenModal,
			questionMobileModalOpen,
			setQuestionMobileModalOpen,
			questionStatus,
			statusQuery
		};
	}, [
		isLoggedIn,
		setIsLoggedIn,
		onLoggedIn,
		onLogout,
		setOpenModal,
		questionMobileModalOpen,
		setQuestionMobileModalOpen,
		questionStatus,
		statusQuery
	]);

	return <BetaTestersContext.Provider value={value}>{children}</BetaTestersContext.Provider>;
};

export default BetaTestersProvider;
