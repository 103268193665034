import { makeStyles } from '@material-ui/styles';

const styles = makeStyles({
	footer: {
		position: 'fixed',
		bottom: 0,
		right: 0,
		left: 0,
		padding: '8px 16px',
		backgroundColor: '#F0F2F4',
		display: 'flex',
		justifyContent: 'space-between',
		zIndex: 9999999
	},
	footerSpan: {
		fontFamily: 'bb-text-medium',
		fontSize: '12px',
		color: '#6C7077'
	}
});

export default styles;
