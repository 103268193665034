import { Button, Dialog, DialogContent, IconButton } from '@mui/material';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import DividerTitle from '../DiviverTitle';
import Title from '../Title';
import { Cancel } from '@mui/icons-material';
import Paragraph from '../Paragraph';
import { useEffect } from 'react';
import BetatesterService from '../../services/betatesterService';
import useBetaTesterContext from '../../contexts/useBetaTestersContext';

interface DeleteAccountModalProps {
	deleteAccountMobileModalOpen: boolean;
	setDeleteAccountMobileModalOpen: (value: boolean) => void;
}

const DeleteAccountMobileModal = ({
	deleteAccountMobileModalOpen,
	setDeleteAccountMobileModalOpen
}: DeleteAccountModalProps) => {
	const { onLogout } = useBetaTesterContext();

	const mutation = useMutation({
		mutationFn: BetatesterService.deleteAccount,
		onSuccess: () => {
			onLogout();
			toast('Seu cadastro foi excluído.', { type: 'info' });
		},
		onError: () => {
			console.log('Ocorreu um erro na solicitação!');
		}
	});

	useEffect(() => {
		return () => setDeleteAccountMobileModalOpen(false);
	}, []);

	return (
		<Dialog open={deleteAccountMobileModalOpen} fullScreen>
			<DialogContent>
				<div>
					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
						<div style={{ marginBottom: '24px' }}>
							<div>
								<Title fontSize="22px" color="#465EFF" style={{ lineHeight: '35px' }}>
									Tem certeza que deseja excluir o seu <br /> cadastro?
								</Title>
								<DividerTitle marginBottom={false} />
							</div>
						</div>
						<IconButton onClick={() => setDeleteAccountMobileModalOpen(false)} sx={{ marginLeft: '20px' }}>
							<Cancel sx={{ color: '#888D95' }} />
						</IconButton>
					</div>
					<Paragraph style={{ color: '#6C7077' }}>
						Ao prosseguir, suas respostas e seus dados serão completamente excluídos da <br /> plataforma SegTesters.
						Mas você pode voltar quando quiser, bastando <br /> realizar um novo cadastro ;)
					</Paragraph>
					<div>
						<Button
							fullWidth
							onClick={() => mutation.mutate()}
							variant="contained"
							sx={{
								fontFamily: 'bb-title-bold',
								fontSize: '16px',
								color: '#FCFDFE',
								marginTop: '32px',
								backgroundColor: '#F57B74',
								'&:hover': {
									backgroundColor: '#F57B74'
								}
							}}
						>
							CONFIRMAR EXCLUSÃO
						</Button>
						<Button
							onClick={() => setDeleteAccountMobileModalOpen(false)}
							fullWidth
							variant="contained"
							sx={{
								fontFamily: 'bb-title-bold',
								fontSize: '16px',
								color: '#3354FD',
								marginTop: '16px',
								backgroundColor: '#E4ECFF',
								'&:hover': {
									backgroundColor: '#E4ECFF'
								}
							}}
						>
							CANCELAR
						</Button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default DeleteAccountMobileModal;
