import { Button, Dialog, IconButton, TextField } from '@mui/material';
import { useTheme } from '@material-ui/styles';
import { Theme } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import useStyles from './styles';
import Title from '../Title';
import { Close } from '@mui/icons-material';
import SignUpService from '../../services/betatesterService';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { maskCpf, unmaskCpf } from '../../utils/maskCpf';
import Paragraph from '../Paragraph';

interface IModalSignUpMobile {
	modalSignUpMobileOpen: boolean;
	setModalSignUpMobileOpen: (value: boolean) => void;
	setModalLoginMobileOpen: (value: boolean) => void;
}

interface IFormSignUpInput {
	nr_cpf: string;
	dt_birth: string;
	txt_name: string;
	txt_email: string;
}

const ModalSignUpMobile = ({
	modalSignUpMobileOpen,
	setModalSignUpMobileOpen,
	setModalLoginMobileOpen
}: IModalSignUpMobile) => {
	const styles = useStyles();

	const theme = useTheme<Theme>();

	const { control, handleSubmit } = useForm<IFormSignUpInput>({
		defaultValues: {
			nr_cpf: '',
			dt_birth: '',
			txt_name: '',
			txt_email: ''
		}
	});

	const mutation = useMutation({
		mutationFn: SignUpService.signUp,
		onSuccess: () => {
			toast('Cadastro efetuado!', { type: 'success' });
		},
		onError: (error: AxiosError<{ message: string }>) => {
			toast(error.response?.data.message, { type: 'error' });
		}
	});

	const onSubmit = (data: IFormSignUpInput) => {
		const clearCpf = unmaskCpf(data.nr_cpf);

		mutation.mutate({ ...data, nr_cpf: clearCpf });
	};

	return (
		<Dialog open={modalSignUpMobileOpen} fullScreen>
			<div className={styles.loginWrapper}>
				<div className={styles.titleWrapper}>
					<Title fontSize="22px" color="#4E5155">
						Cadastre-se
					</Title>
					<IconButton onClick={() => setModalSignUpMobileOpen(false)}>
						<Close sx={{ color: '#313338' }} />
					</IconButton>
				</div>
				<form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '24px' }}>
					<Controller
						name="nr_cpf"
						control={control}
						rules={{ required: true, pattern: /[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}/ }}
						render={({ field: { name, onChange, value }, fieldState: { error } }) => {
							return (
								<TextField
									name={name}
									value={value}
									onChange={event => onChange(maskCpf(event.target.value))}
									type="text"
									label="CPF"
									error={!!error}
									helperText={error && 'CPF inválido.'}
									placeholder="000.000.000-00"
									variant="filled"
									sx={{ color: '#F0F2F4' }}
									fullWidth
								/>
							);
						}}
					/>
					<Controller
						name="dt_birth"
						control={control}
						rules={{ required: true }}
						render={({ field: { name, onChange, value }, fieldState: { error } }) => {
							return (
								<TextField
									InputLabelProps={{ shrink: true }}
									type="date"
									label="Data de nascimento"
									placeholder="dd/mm/aaaa"
									name={name}
									error={!!error}
									value={value}
									onChange={onChange}
									helperText={error && 'Preecha o campo corretamente.'}
									variant="filled"
									sx={{ color: '#F0F2F4', marginTop: '20px' }}
									fullWidth
								/>
							);
						}}
					/>
					<Controller
						name="txt_name"
						control={control}
						rules={{ required: true }}
						render={({ field: { name, onChange, value }, fieldState: { error } }) => {
							return (
								<TextField
									type="text"
									label="Nome"
									placeholder="Exemplo: João Silva"
									name={name}
									error={!!error}
									value={value}
									onChange={onChange}
									helperText={error && 'Preecha o campo corretamente.'}
									variant="filled"
									sx={{ color: '#F0F2F4', marginTop: '20px' }}
									fullWidth
								/>
							);
						}}
					/>
					<Controller
						name="txt_email"
						control={control}
						rules={{ required: true }}
						render={({ field: { name, onChange, value }, fieldState: { error } }) => {
							return (
								<TextField
									type="email"
									label="Email"
									placeholder="Exemplo: joao_silva@gmail.com"
									name={name}
									error={!!error}
									value={value}
									onChange={onChange}
									helperText={error && 'Preecha o campo corretamente.'}
									variant="filled"
									sx={{ color: '#F0F2F4', marginTop: '20px' }}
									fullWidth
								/>
							);
						}}
					/>

					<Paragraph fontSize="14px" style={{ color: '#6C7077', marginTop: '24px' }}>
						Ao clicar no botão “Cadastrar”, você declara concordar com a nossa Política de Privacidade e a enviar seus
						dados para a BB Seguros e coligadas.
					</Paragraph>

					<Button
						sx={{
							padding: '12px 0',
							marginTop: '25px',
							bgcolor: theme.palette.secondary.main,
							fontFamily: 'bb-title-bold',
							'&:hover': {
								backgroundColor: theme.palette.secondary.main
							}
						}}
						type="submit"
						fullWidth
					>
						Cadastrar
					</Button>
				</form>
				<div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
					<Button
						onClick={() => {
							setModalLoginMobileOpen(true);
							setModalSignUpMobileOpen(false);
						}}
						variant="text"
						sx={{ color: '#465EFF', fontFamily: 'bb-text-medium', fontSize: '16px' }}
					>
						Já sou cadastrado
					</Button>
				</div>
			</div>
		</Dialog>
	);
};

export default ModalSignUpMobile;
