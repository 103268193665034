import { Button, Dialog, Grid, IconButton } from '@mui/material';
import Title from '../../components/Title';
import { CSSProperties, createRef, useEffect, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import gsap from 'gsap';
import QuestionService from '../../services/questionService';
import useStyles from './styles';
import { PayloadProfile, decodeToken } from '../../utils/decodeToken';
import BetaIdAndAnswerService from '../../services/betaQuestionService';
import secureStorage from 'react-secure-storage';
import { Close } from '@mui/icons-material';
import Loading from '../Loading';
import QuestionDone from '../QuestionDone';
import FormProgress from '../FormProgress';
import QuestionInput from '../QuestionInput';

interface IQuestionMobileModalProps {
	customStyles?: CSSProperties;
	questionMobileModalOpen: boolean;
	setQuitQuestionMobileOverlayOpen: (value: boolean) => void;
}

type InputFields = {
	[key: string]: string | string[];
};

const ModalQuestionMobile = ({
	questionMobileModalOpen,
	setQuitQuestionMobileOverlayOpen
}: IQuestionMobileModalProps) => {
	const [formIndex, setFormIndex] = useState<number>(0);
	const [allFields, setAllFields] = useState<InputFields>({});
	const [questionsAnswered, setQuestionsAnswered] = useState<boolean>(false);

	const questions = useQuery('questions', QuestionService.getQuestions);

	const questionRefs = createRef<HTMLDivElement>();

	const styles = useStyles();

	const formLength = questions.data?.length;

	const mutation = useMutation({
		mutationFn: BetaIdAndAnswerService.postBetaIdAndAnswer,
		onSuccess: data => {
			const userProfile = secureStorage.getItem('userProfile') as PayloadProfile;

			const newProfile: PayloadProfile = {
				...userProfile,
				txt_question_status: data.txt_question_status as 'CP' | 'IN' | 'IN_RE'
			};

			secureStorage.setItem('userProfile', newProfile);

			setQuestionsAnswered(true);
		}
	});

	const animeToLeft = () => {
		if (questionRefs.current !== null) {
			gsap.fromTo(
				questionRefs.current,
				{
					transform: 'translateX(20px)',
					opacity: 0
				},
				{ transform: 'translateX(0)', opacity: 1 }
			);
		}
	};

	const animeToRight = () => {
		if (questionRefs.current !== null) {
			gsap.fromTo(
				questionRefs.current,
				{
					transform: 'translateX(-20px)',
					opacity: 0
				},
				{ transform: 'translateX(0)', opacity: 1 }
			);
		}
	};

	const nextQuestion = () => {
		if (formLength) {
			if (formIndex + 1 < formLength) {
				setFormIndex(value => value + 1);
				animeToLeft();
			}
		}
	};

	const previousQuestion = () => {
		if (formLength) {
			if (formIndex > 0) {
				setFormIndex(value => value - 1);
				animeToRight();
			}
		}
	};

	const onSubmit = () => {
		// const answers = Object.values(allFields);

		// const allAnswers: number[] = [];

		// answers.forEach(item => {
		// 	if (Array.isArray(item)) {
		// 		item.forEach(answer => {
		// 			allAnswers.push(Number(answer));
		// 		});
		// 	} else {
		// 		allAnswers.push(Number(item));
		// 	}
		// });

		const { id } = decodeToken();

		// const answersAndIdUsers = allAnswers.map(item => {
		// 	return {
		// 		id_betatester: id,
		// 		id_answer: item
		// 	};
		// });

		mutation.mutate({ id_betatester: id, questions: allFields });
	};

	useEffect(() => {
		return () => setAllFields({});
	}, []);

	return (
		<Dialog open={questionMobileModalOpen} fullScreen>
			<div className={styles.questionWrapper}>
				<div className={styles.titleWrapper}>
					<Title fontSize="22px" color="#4E5155">
						Questionário de perfil
					</Title>
					<IconButton
						onClick={() => {
							setQuitQuestionMobileOverlayOpen(true);
						}}
					>
						<Close sx={{ color: '#313338' }} />
					</IconButton>
				</div>
			</div>
			<div>
				{mutation.isLoading && <Loading />}
				<Grid container>
					{questionsAnswered ? (
						<QuestionDone />
					) : (
						<div className={styles.questionsWrapper}>
							<FormProgress currentIndex={formIndex} totalQuestions={questions.data?.length} />
							{questions.data && (
								<QuestionInput
									question={questions.data[formIndex]}
									questionRefs={questionRefs}
									allFields={allFields}
									setAllfields={setAllFields}
								/>
							)}
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									marginTop: '30px',
									borderTop: '1px solid #E5E7EB',
									paddingTop: '24px'
								}}
							>
								<Button
									disabled={!(formIndex > 0)}
									className={styles.previousButton}
									variant="contained"
									onClick={previousQuestion}
									sx={{
										backgroundColor: '#E4ECFF',
										color: '#3354FD',
										padding: '12px 16px',
										fontFamily: 'bb-title-bold'
									}}
								>
									VOLTAR
								</Button>
								{formIndex + 1 === formLength ? (
									<Button
										className={styles.finishButton}
										variant="contained"
										onClick={onSubmit}
										sx={{
											backgroundColor: '#FDF429',
											color: '#3354FD',
											padding: '12px 16px',
											fontFamily: 'bb-title-bold'
										}}
									>
										CONCLUIR
									</Button>
								) : (
									<Button
										disabled={!(formIndex + 1 < formLength!)}
										className={styles.nextButton}
										variant="contained"
										onClick={() => nextQuestion()}
										sx={{
											backgroundColor: '#FDF429',
											color: '#3354FD',
											padding: '12px 16px',
											fontFamily: 'bb-title-bold'
										}}
									>
										AVANÇAR
									</Button>
								)}
							</div>
						</div>
					)}
				</Grid>
			</div>
		</Dialog>
	);
};

export default ModalQuestionMobile;
