import { CSSProperties, ReactNode } from 'react';
import useStyles from './styles';

interface IParagraphProps {
	children: ReactNode;
	fontSize?: string | number;
	style?: CSSProperties;
}

const Paragraph = ({ children, fontSize = '16px', style }: IParagraphProps) => {
	const styles = useStyles();

	return (
		<p className={styles.paragraph} style={{ ...style, fontSize: fontSize }}>
			{children}
		</p>
	);
};

export default Paragraph;
