import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
	whatIs: {},
	img: {
		maxWidth: '384px'
	},
	infoWrapper: {
		marginTop: '32px'
	},
	benefits: {
		marginTop: '80px'
	},
	customDotClass: {
		position: 'relative',
		marginTop: '10px'
	}
});

export default useStyles;
