import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
	menuWrapper: {
		padding: '18px 16px'
	},
	titleWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '10px',
		borderBottom: '1px solid #B4B9C1',
		marginTop: '16px',
		paddingBottom: '15px'
	},
	userInfoWrapper: {
		display: 'flex',
		alignItems: 'center',
		marginTop: '8px'
	},
	userInitials: {
		fontFamily: 'bb-text-medium',
		fontSize: '18px',
		color: '#465EFF',
		padding: '18px 17px',
		backgroundColor: '#EDF2FF',
		borderRadius: '4px',
		marginRight: '16px'
	},
	userName: {
		fontFamily: 'bb-text-medium',
		fontSize: '18px',
		color: '#111214',
		marginBottom: '4px'
	},
	userEmail: {
		fontFamily: 'bb-text-regular',
		fontSize: '16px',
		color: '#6C7077'
	}
});

export default useStyles;
