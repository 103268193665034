import { BrowserRouter } from 'react-router-dom';
import BetaTesterRoutes from './routes';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@material-ui/styles';
import { QueryClient, QueryClientProvider } from 'react-query';
import BetaTestersProvider from './contexts/betaTestersContext';
import { ToastContainer } from 'react-toastify';

const theme = createTheme({
	palette: {
		primary: {
			main: '#5A6FFF'
		},
		secondary: {
			main: '#FCFC30'
		}
	}
});

function App() {
	const queryClient = new QueryClient();

	return (
		<>
			<ToastContainer />
			<ThemeProvider theme={theme}>
				<QueryClientProvider client={queryClient}>
					<BrowserRouter>
						<BetaTestersProvider>
							<BetaTesterRoutes />
						</BetaTestersProvider>
					</BrowserRouter>
				</QueryClientProvider>
			</ThemeProvider>
		</>
	);
}

export default App;
