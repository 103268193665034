import { Button, Dialog, DialogContent, IconButton } from '@mui/material';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import DividerTitle from '../DiviverTitle';
import Title from '../Title';
import TrashIllustration from '../../assets/images/svgs/trash.svg';
import { Cancel } from '@mui/icons-material';
import Paragraph from '../Paragraph';
import { useEffect } from 'react';
import BetatesterService from '../../services/betatesterService';
import useBetaTesterContext from '../../contexts/useBetaTestersContext';

interface DeleteAccountModalProps {
	deleteAccountModalOpen: boolean;
	setDeleteAccountModalOpen: (value: boolean) => void;
}

const DeleteAccountModal = ({ deleteAccountModalOpen, setDeleteAccountModalOpen }: DeleteAccountModalProps) => {
	const { onLogout } = useBetaTesterContext();

	const mutation = useMutation({
		mutationFn: BetatesterService.deleteAccount,
		onSuccess: () => {
			onLogout();
			toast('Seu cadastro foi excluído.', { type: 'info' });
		},
		onError: () => {
			console.log('Ocorreu um erro na solicitação!');
		}
	});

	useEffect(() => {
		return () => setDeleteAccountModalOpen(false);
	}, []);

	return (
		<Dialog open={deleteAccountModalOpen} maxWidth="md">
			<DialogContent>
				<div>
					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
						<div style={{ display: 'flex', marginBottom: '24px', alignItems: 'flex-start' }}>
							<img src={TrashIllustration} alt="Avatar usuário" />
							<div style={{ marginLeft: '24px' }}>
								<Title fontSize="28px" color="#465EFF" style={{ lineHeight: '35px' }}>
									Tem certeza que deseja excluir o seu <br /> cadastro?
								</Title>
								<DividerTitle marginBottom={false} />
							</div>
						</div>
						<IconButton onClick={() => setDeleteAccountModalOpen(false)} sx={{ marginLeft: '20px' }}>
							<Cancel sx={{ color: '#888D95' }} />
						</IconButton>
					</div>
					<Paragraph style={{ color: '#6C7077' }}>
						Ao prosseguir, suas respostas e seus dados serão completamente excluídos da <br /> plataforma SegTesters.
						Você pode voltar quando quiser, basta <br /> realizar um novo cadastro ;)
					</Paragraph>
					<div style={{ width: '100%', border: '1px solid #E5E7EB', margin: '24px 0' }}></div>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Button
							onClick={() => setDeleteAccountModalOpen(false)}
							variant="contained"
							sx={{
								fontFamily: 'bb-title-bold',
								fontSize: '16px',
								color: '#3354FD',
								backgroundColor: '#E4ECFF',
								'&:hover': {
									backgroundColor: '#E4ECFF'
								}
							}}
						>
							CANCELAR
						</Button>
						<Button
							onClick={() => mutation.mutate()}
							variant="contained"
							sx={{
								fontFamily: 'bb-title-bold',
								fontSize: '16px',
								color: '#FCFDFE',
								backgroundColor: '#F57B74',
								'&:hover': {
									backgroundColor: '#F57B74'
								}
							}}
						>
							CONFIRMAR EXCLUSÃO
						</Button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default DeleteAccountModal;
