import api from '../api/axios';

type InputFields = {
	[key: string]: string | string[];
};

interface IBetaQuestionPayload {
	id_betatester: number;
	questions: InputFields;
}

const postBetaIdAndAnswer = async (payload: IBetaQuestionPayload) => {
	const response = await api.post('/betatester/betatester-question', payload);

	return response.data;
};

const BetaIdAndAnswerService = {
	postBetaIdAndAnswer
};

export default BetaIdAndAnswerService;
