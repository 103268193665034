import { CSSProperties, ReactNode } from 'react';
import Title from '../Title';
import Paragraph from '../Paragraph';
import useStyles from './styles';

interface ITestCardProps {
	title: string;
	status: string;
	icon: ReactNode;
	disabled?: boolean;
	onClick?: () => void;
	customStyles?: CSSProperties;
}

const TestCard = ({ title, status, icon, disabled = false, onClick, customStyles }: ITestCardProps) => {
	const styles = useStyles();

	return (
		<div
			className={styles.card}
			style={{
				...customStyles,
				cursor: disabled ? 'default' : 'pointer'
			}}
			onClick={onClick}
		>
			<Title fontSize="24px" color="#111214">
				{title}
			</Title>
			<div style={{ marginTop: '85px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<Paragraph fontSize="14px" style={{ color: '#6C7077', marginRight: '15px' }}>
					{status}
				</Paragraph>
				<div>{icon}</div>
			</div>
		</div>
	);
};

export default TestCard;
