import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
	container: {
		margin: '0 auto',
		padding: '0 16px'
	}
});

export default useStyles;
