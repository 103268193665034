import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
	paragraph: {
		fontFamily: 'bb-text-regular',
		lineHeight: '28.8px',
		color: '#6C7077'
	}
});

export default useStyles;
