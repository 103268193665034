import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
	benefitWrapper: {
		padding: '24px',
		border: '1px solid #B4B9C1',
		borderRadius: '5px',
		backgroundColor: '#F9F7FF'
	},
	illustration: {
		width: '100px',
		marginBottom: '16px'
	}
});

export default useStyles;
