import { Button, Dialog, DialogContent, IconButton } from '@mui/material';
import Title from '../Title';
import { Cancel } from '@mui/icons-material';
import Paragraph from '../Paragraph';
import useStyles from './styles';
import DividerTitle from '../DiviverTitle';

interface IOverlayQuitQuestionMobile {
	modalOpen: boolean;
	setModalOpen(value: boolean): void;
	quitButtonFn: () => void;
}

const OverlayQuitQuestionMobile = ({ modalOpen, setModalOpen, quitButtonFn }: IOverlayQuitQuestionMobile) => {
	const handleCloseModal = () => {
		setModalOpen(false);
	};

	const styles = useStyles();

	return (
		<>
			<Dialog open={modalOpen}>
				<DialogContent>
					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<Title fontSize="22px" color="#001527">
							Sair do questionário
						</Title>
						<IconButton onClick={handleCloseModal}>
							<Cancel sx={{ color: '#888D95' }} />
						</IconButton>
					</div>
					<DividerTitle />
					<Paragraph fontSize="14px" style={{ color: '#66707D', maxWidth: '320px', marginTop: '10px' }}>
						Deseja realmente interromper seu questionário de perfil? Saindo agora, todas as informações já preenchidas
						serão perdidas.
					</Paragraph>
					<div>
						<Button
							fullWidth
							variant="contained"
							onClick={handleCloseModal}
							className={styles.keepButton}
							sx={{
								backgroundColor: '#FDF429',
								color: '#3354FD',
								fontFamily: 'bb-title-bold',
								fontSize: '12px',
								marginTop: '32px'
							}}
						>
							CONTINUAR PREECHENDO
						</Button>
						<Button
							fullWidth
							sx={{
								backgroundColor: '#E4ECFF',
								color: '#3354FD',
								fontFamily: 'bb-title-bold',
								fontSize: '12px',
								marginTop: '16px'
							}}
							className={styles.exitButton}
							variant="contained"
							onClick={() => quitButtonFn()}
						>
							SAIR
						</Button>
					</div>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default OverlayQuitQuestionMobile;
