import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
	closeButtonWrapper: {
		display: 'flex',
		justifyContent: 'flex-end'
	},
	modalWrapper: {
		padding: '20px',
		backgroundColor: '#FEFEFE',
		width: '288px'
	},
	logoSpan: {
		fontFamily: 'JetBrainsMono-Medium',
		fontSize: '14px',
		marginTop: '5px',
		color: '#66707D'
	}
});

export default useStyles;
