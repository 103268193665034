import { Route, Routes } from 'react-router-dom';
import useBetaTesters from './contexts/useBetaTestersContext';
import HomePublic from './pages/HomePublic';
import NavBarBetaTester from './components/NavBarBetaTester';
import MyTests from './pages/MyTests';
import WhatIs from './pages/WhatIs';
import Faq from './pages/Faq';
import FooterBeta from './components/FooterBeta';
import Redirect from './pages/Redirect';
import Error from './pages/Error';
import Question from './pages/Question';

const BetaTesterRoutes = () => {
	const { isLoggedIn } = useBetaTesters();

	return (
		<>
			{isLoggedIn ? (
				<>
					<NavBarBetaTester />
					<Routes>
						<Route path="/" element={<MyTests />} />
						<Route path="/sobre" element={<WhatIs customStyles={{ paddingTop: '148px' }} />} />
						<Route path="/faq" element={<Faq customStyles={{ paddingTop: '148px' }} />} />
						<Route path="/acessar-teste" element={<Redirect customStyles={{ paddingTop: '148px' }} />} />
						<Route path="/perguntas" element={<Question customStyles={{ paddingTop: '148px' }} />} />{' '}
						<Route path="*" element={<Error />} />
					</Routes>
					<FooterBeta />
				</>
			) : (
				<>
					<Routes>
						<Route path="/" element={<HomePublic />} />
						<Route path="*" element={<Error />} />
					</Routes>
				</>
			)}
		</>
	);
};

export default BetaTesterRoutes;
