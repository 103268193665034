import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
	exitButton: {
		'&:hover': {
			background: '#E4ECFF !important'
		}
	},
	keepButton: {
		'&:hover': {
			background: '#FDF429 !important'
		}
	}
});

export default useStyles;
