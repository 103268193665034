import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
	loginWrapper: {
		padding: '18px 16px'
	},
	titleWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '10px',
		borderBottom: '1px solid #B4B9C1',
		marginTop: '16px',
		paddingBottom: '15px'
	},
	userInfoWrapper: {
		display: 'flex',
		alignItems: 'center',
		marginTop: '8px'
	}
});

export default useStyles;
