import { useRef } from 'react';
import useStyles from './styles';

interface IFormProgress {
	currentIndex: number;
	totalQuestions?: number;
}

const FormProgress = ({ currentIndex, totalQuestions }: IFormProgress) => {
	const progressElement = useRef<HTMLDivElement>(null);

	const progressWidth = progressElement.current?.offsetWidth as number;

	const progressPercent = progressWidth / (totalQuestions as number);

	const currentProgressPercent = (currentIndex + 1) * progressPercent;

	const styles = useStyles();

	return (
		<div className={styles.progressMainWrapper}>
			<div ref={progressElement} className={styles.progressWrapper}>
				<div
					className={styles.progressContent}
					style={{ width: currentProgressPercent ? currentProgressPercent : 0 }}
				></div>
			</div>
			<div className={styles.progressInfoWrapper}>
				<span className={styles.progressInfoTitle}>Questionário de perfil</span>
				<span className={styles.progressInfo}>{`${currentIndex + 1}/${totalQuestions}`}</span>
			</div>
		</div>
	);
};

export default FormProgress;
