import { Button } from '@mui/material';
import ErrorIllustration from '../../assets/images/svgs/error-illustration.svg';
import { useNavigate } from 'react-router-dom';

const Error = () => {
	const navigate = useNavigate();

	return (
		<div style={{ height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<img src={ErrorIllustration} alt="Ilustração error" />
				<div style={{ marginLeft: '72px', lineHeight: '40px', color: '#465EFF' }}>
					<span style={{ fontFamily: 'bb-title-bold', fontSize: '32px' }}>
						<span style={{ display: 'inline' }}>Ops...</span>
						<br />
						<span>alguma coisa deu errado!</span>
					</span>
					<p
						style={{
							color: 'rgba(0,21,39, 50%',
							fontFamily: 'bb-text-regular',
							fontSize: '17px',
							lineHeight: '25px',
							width: '424px',
							marginTop: '10px'
						}}
					>
						A página que você procura pode ter sido removida ou estar indisponível temporariamente.
					</p>
					<Button
						sx={{
							color: '#465EFF',
							bgcolor: '#FCFC30',
							fontFamily: 'bb-title-bold',
							padding: '9px 16px',
							marginTop: '29px'
						}}
						onClick={() => navigate('/')}
					>
						IR PARA A PÁGINA INICIAL
					</Button>
				</div>
			</div>
		</div>
	);
};

export default Error;
