import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
	questionWrapper: {
		padding: '18px 16px'
	},
	titleWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '10px',
		borderBottom: '1px solid #B4B9C1',
		marginTop: '16px',
		paddingBottom: '15px'
	},
	questionsWrapper: {
		padding: '0 16px',
		width: '100%'
	},
	previousButton: {
		'&:hover': {
			background: '#E4ECFF !important'
		},
		'&:disabled': {
			background: '#d9d9d9'
		}
	},
	nextButton: {
		'&:hover': {
			background: '#FDF429 !important'
		},
		'&:disabled': {
			background: '#d9d9d9'
		}
	},
	finishButton: {
		'&:hover': {
			background: '#FDF429 !important'
		},
		'&:disabled': {
			background: '#d9d9d9'
		}
	}
});

export default useStyles;
