import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
	previousButton: {
		'&:hover': {
			background: '#E4ECFF !important'
		},
		'&:disabled': {
			background: '#d9d9d9'
		}
	},
	nextButton: {
		'&:hover': {
			background: '#FDF429 !important'
		},
		'&:disabled': {
			background: '#d9d9d9'
		}
	},
	finishButton: {
		'&:hover': {
			background: '#FDF429 !important'
		},
		'&:disabled': {
			background: '#d9d9d9'
		}
	}
});

export default useStyles;
